import React from 'react'
import Layout from '../../templates/Login'
import Form from '../../modules/Form'


const NewPasswordPage = () => {
    return (
        <Layout>
            <Form view='reset'/>
        </Layout>
    )
}

export default NewPasswordPage
