import React from 'react'
import Header from '../modules/Header'
import { Box } from 'rebass/styled-components'
import Meta from '../components/general/Meta'

const LoginLayout = ({ children, type }) => {
    const url = typeof window !== 'undefined' ? window.location.href : ''
    const colour = url.includes('restorers') ? 'aquaMedium' : 'black'

    return (
        <>
            <Meta />
            <Box bg={colour} height='100vh'>
                <Header type='login' />
                <Box variant='container'>{children}</Box>
            </Box>
        </>
    )
}

export default LoginLayout
