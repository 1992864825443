import React from 'react'
import { Link } from 'gatsby'
import { Box, Flex, Text, Button } from 'rebass/styled-components'
import styled from 'styled-components'
import { Input } from '@rebass/forms/styled-components'
import { useForm } from 'react-hook-form';

const StyledForm = styled(Box)`
    input {
        background-color: ${(props) => props.theme.colors.black};
        color: ${(props) => props.theme.colors.white};
        margin-bottom: ${(props) => props.theme.space.md};
        transition-duration: 300ms;
        transition-timing-function: ease-out;
        transition-property: border-color; 
        will-change: border-color;
    }

    input:focus {
            border-color: ${(props) => props.theme.colors.aquaLight};
        }
        &.error {
            border-color: ${(props) => props.theme.colors.errorLight};
            border-width: 2px;
        }
    .error {
        color: ${(props) => props.theme.colors.errorDark};
    }
`

const ResetLink = styled(Link)`
    color: ${(props) => props.theme.colors.grey};
`

const ResetForm = ( ) => {
    const url = typeof window !== 'undefined' ? window.location.href : '';
    const destination = url.includes('restorers') ? '/restorers/' : '/clients/welcome'
    const { register, handleSubmit } = useForm();
    const onSubmit = (data) => console.log(data)

    return (
        <Box>
            <StyledForm as='form' maxWidth={['310px', '470px', '543px']} margin= 'auto' onSubmit={handleSubmit(onSubmit)}>
                <Box pb='xs' mb='md' sx={{ borderBottom: '1px solid rgba(255,255,255,0.1)' }}>
                    <Text as='h2' variant='h2'>
                        ENTER A NEW PASSWORD
                    </Text>
                </Box>
                <Input
                    enterKeyHint="next"
                    id="form-newpwass"
                    type="password"
                    name="newpass"
                    placeholder="New password"
                    required= 'true'
                    {...register('form-newpass', { required: true })}/>
                <Input
                    autoComplete="password"
                    enterKeyHint="next"
                    id="form-confpass"
                    type="password"
                    name="confpasss"
                    placeholder="Retype new password"
                    {...register('form-confpass', { required: true })}/>
                <Flex justifyContent='space-between'>
                    <Link to={destination}>
                        <Button
                            aria-pressed="false"
                            enterKeyHint="done"
                            type="submit"
                            variant="primary"
                            to='/'>
                            Submit
                        </Button>
                    </Link>
                    <ResetLink to='/' sx={{ cursor: 'pointer' }}>Cancel</ResetLink>
                </Flex>
            </StyledForm>
        </Box>
    )
}
export default ResetForm
