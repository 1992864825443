import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'gatsby'
import { Box, Flex, Text, Button } from 'rebass/styled-components'
import styled from 'styled-components'
import { Input } from '@rebass/forms/styled-components'
import { loginUser } from '../../models/Users'
import { useForm } from 'react-hook-form';
import { resetUserPassword } from '../../models/Users'

const StyledForm = styled(Box)`
    input {
        background-color: ${(props) => props.theme.colors.black};
        color: ${(props) => props.theme.colors.white};
        margin-bottom: ${(props) => props.theme.space.md};
        transition-duration: 300ms;
        transition-timing-function: ease-out;
        transition-property: border-color; 
        will-change: border-color;
    }

    input:focus {
            border-color: ${(props) => props.theme.colors.aquaLight};
        }
        &.error {
            border-color: ${(props) => props.theme.colors.errorLight};
            border-width: 2px;
        }
    .error {
        color: ${(props) => props.theme.colors.errorDark};
    }
`

const ResetLink = styled(Link)`
    color: ${(props) => props.theme.colors.grey};
`

const LoginForm = ({ dispatch, unAuthorised, errors }) => {
    const url = typeof window !== 'undefined' ? window.location.href : ''
    const destination = url.includes('restorers') ? '/restorers/' : '/clients/welcome'
    const [view, setView] = useState('login')
    const [state, updateState] = useState({ email: '', password: '' })
    const loginErrors = errors ? <Text as='p' variant='body' color='red' mb='md'> {errors} </Text> : '';
    const notAuthorised = unAuthorised ? <Text as='p' variant='body' color='red'> You are not authorised! </Text> : '';

    const handleSubmit = (event) => {
        const { email, password } = state
        event.preventDefault()
        loginUser(dispatch, email, password)
    };

    const onInputChange = (name, event) => {
        const change = { ...state }
        change[name] = event.target.value
        updateState(change)
    }
    let output = null
    const handleClick = (e, type) => {
        e.preventDefault()
        if (type === 'sent') {
            // console.log('triggering reset')
            resetUserPassword(state.email)
        }
        setView(type)
    }

    switch (view) {
    case 'login':
        output = <>
            <Box m={['sm', 'sm', 'sm', 'sm', '0px']}>
                <Box pb='xs' mb='md' sx={{ borderBottom: '1px solid rgba(255,255,255,0.1)' }}>
                    <Text as='h2' variant='h2'>
                        LOGIN
                    </Text>
                </Box>
                <Input
                    autoComplete="email"
                    enterKeyHint="next"
                    id="form-email"
                    type="text"
                    name="email"
                    placeholder="Email"
                    required= { true }
                    value={state.email}
                    onChange={(e) => onInputChange( 'email', e )}
                />
                <Input
                    autoComplete="password"
                    enterKeyHint="next"
                    id="form-password"
                    type="password"
                    name="password"
                    placeholder="Password"
                    required= { true }
                    value={state.password}
                    onChange={(e) => onInputChange( 'password', e )}
                />
                {loginErrors}
                {notAuthorised}
                <Flex justifyContent='space-between'>
                    <Link to={destination}>
                        <Button
                            aria-pressed="false"
                            enterKeyHint="done"
                            type="submit"
                            variant="primary"
                            onClick={(e) => handleSubmit(e)}>
                            Login
                        </Button>
                    </Link>
                    <Box pl='sm'><ResetLink to='#' onClick={() => setView('forgotten')}>Forgot your password?</ResetLink></Box>
                </Flex>
            </Box>
        </>
        break
    case 'forgotten':
        output = <>
            <Box pb='xs' mb='md' sx={{ borderBottom: '1px solid rgba(255,255,255,0.1)' }}>
                <Text as='h2' variant='h2'>
                    PASSWORD RESET EMAIL
                </Text>
            </Box>
            <Text as='p' variant='body' mb='sm'>
                Please enter the email address associated with the account and if that address is registered with us, you will recieve an email to reset your password
            </Text>
            <Input
                autoComplete="off"
                enterKeyHint="next"
                id="form-email"
                type="email"
                name="email"
                defaultValue='Email address'
                onChange={(e) => onInputChange( 'email', e )}
            />
            <Link to='#' >
                <Button
                    aria-pressed="false"
                    enterKeyHint="done"
                    type="submit"
                    variant="primary"
                    onClick={(e) => handleClick(e, 'sent')}>
                    Send email
                </Button>
            </Link>
        </>
        break
    case 'sent':
        output = <>
            <Box pb='xs' mb='sm' sx={{ borderBottom: '1px solid rgba(255,255,255,0.1)' }}>
                <Text as='h2' variant='h2'>
                    PASSWORD RESET EMAIL
                </Text>
            </Box>
            <Text as='p' variant='body' mb='sm' pb='sm' sx={{ borderBottom: '1px solid rgba(255,255,255,0.1)' }}>
                An email has been sent to your Kidston Portal account email address, <strong>{state.email}</strong>. Follow the steps in the email to reset your password
            </Text>
            <Flex justifyContent='space-between'>
                <Link to='#'>
                    <Button
                        aria-pressed="false"
                        enterKeyHint="done"
                        type="submit"
                        variant="primary"
                        onClick={(e) => handleClick(e, 'login')}>
                        Done
                    </Button>
                </Link>
                <ResetLink to='#' onClick={(e) => handleClick(e, 'login')}>Back to login</ResetLink>
            </Flex>
        </>
        break
    default:
            //
    }


    return (
        <Box>
            <StyledForm as='form' maxWidth={['310px', '470px', '543px']} margin= {['xs', 'auto']}>
                {output}
            </StyledForm>
        </Box>
    )
}

function mapStateToProps(state) {
    return {
        unAuthorised: state.Auth.unAuthorised,
        errors: state.Auth.errors,
    };
}

export default connect(mapStateToProps)(LoginForm);
