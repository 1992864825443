import React from 'react'
import Login from './Login'
import AccountForm from '../components/forms/AccountForm'
import ResetPassword from '../components/forms/ResetPassword'

const Form = ( { view } ) => {
    const isSSR = typeof window === 'undefined'

    let output = null

    switch (view) {
    case 'login':
        output = <Login view='login'/>
        break
    case 'reset':
        output = <ResetPassword view='login'/>
        break
    case 'account':
        output = <AccountForm />
        break
    }

    return (
        <>
            {!isSSR && (
                output
            )}
        </>
    )
}

export default Form
