import React from 'react'
import LoginForm from '../components/forms/LoginForm'
import ResetPassword from '../components/forms/ResetPassword'

const Login = ( { view } ) => {
    let output = null
    switch (view) {
    case 'login':
        output= <LoginForm />
        break
    case 'reset':
        output= <ResetPassword />
        break
    default:
            //
    }
    return (
        output
    )
}

export default Login
